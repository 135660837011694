<template>
  <v-container>
    <!-- Banner header -->
    <Banner :title="'Prestatarias'">
      <template v-slot:actions>
        <v-btn small @click="downloadAllBorrowersReport()">
          <v-img :src="require(`@/assets/icons/download_pdf.png`)" max-width="24" class="mr-2" />
          Reporte de prestatarias
        </v-btn>
      </template>
    </Banner>

    <!-- Data table admins -->
    <v-card class="my-8">
      <DataTable
        :headers="tableHeaders"
        :items="borrowers"
        :totalItems="$store.state.borrowers.totalCountBorrowers"
        @clickNextIcon="handleNextIcon"
        @clickPrevIcon="handlePrevIcon"
        @inputSearch="handleSearch"
        @changeItemsPerPage="getBorrowers"
      >
        <template v-slot:[`item.id`]="{ item }">
          <router-link :to="`/prestatarias/${item.id}`">{{ item.id }}</router-link>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ formatDateL(item.created_at) }}
        </template>
      </DataTable>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Banner from "@/components/elements/Banner";
import DataTable from "@/components/elements/DataTable";
import FormatDateMixin from "@/mixins/FormatDateMixin";

export default {
  name: "Borrowers",
  components: {
    Banner,
    DataTable
  },
  mixins: [FormatDateMixin],
  data() {
    return {
      // Borrowers datatable
      tableHeaders: [
        { text: "Id", value: "id", sortable: false },
        { text: "Documento de identidad", value: "identity_document", sortable: false },
        { text: "Teléfono", value: "phone", sortable: false },
        { text: "address", value: "address.name", sortable: false },
        { text: "Nombre", value: "first_name", sortable: false },
        { text: "Apellidos", value: "last_name", sortable: false },
        { text: "Email", value: "email", sortable: false },
        { text: "Fecha creación", value: "created_at" }
      ]
    };
  },
  computed: {
    ...mapState({
      borrowers: (state) => state.borrowers.borrowers
    })
  },
  async created() {
    await this.getBorrowers({ size: 10, sort: { created_at: "DESC" } });
  },
  methods: {
    ...mapActions({
      getBorrowers: "borrowers/getBorrowers"
    }),

    async handlePrevIcon(elasticData) {
      await this.getBorrowers(elasticData);
    },

    async handleNextIcon(elasticData) {
      await this.getBorrowers(elasticData);
    },

    async handleSearch(elasticData) {
      await this.getBorrowers(elasticData);
    },

    async downloadAllBorrowersReport() {
      const { data: path } = await this.$store.dispatch("borrowers/downloadAllBorrowersReport");
      window.open(path, "_blank");
    }
  }
};
</script>
